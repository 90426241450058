import { createReducer } from '@reduxjs/toolkit';
import {
  getClassification,
  getCountries,
  getImsList,
  getOrganizationCodeList,
  getRetailers,
  getStopCodeList,
} from 'features/classification/classificationActions';
import { ClassificationGroup, ClassificationKey } from 'dto/classification';
import { CodeList } from 'dto/codeList';
import { Country } from 'dto/country';
import { ImsRegisterLite } from 'dto/imsRegister';
import { Classifier } from '@fleet/shared/dto/classifier';
import { Organization } from 'dto/organization';

type ClassificationState = Record<ClassificationKey, Array<Classifier>> & {
  [ClassificationGroup.COUNTRY]: Array<Country>;
  [ClassificationGroup.IMS]: Array<ImsRegisterLite>;
  [ClassificationGroup.ORGANIZATION_CODE_LIST]: Array<CodeList>;
  [ClassificationGroup.RETAILER]: Array<Organization>;
  [ClassificationGroup.STOP_CODE_LIST]: Array<CodeList>;
};

const initialState: ClassificationState = {} as ClassificationState;
export const classificationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getClassification.fulfilled, (state, action) => {
      const [groupName, data] = action.payload;
      state[groupName] = data;
    })
    .addCase(getStopCodeList.fulfilled, (state, action) => {
      state.STOP_CODE_LIST = action.payload;
    })
    .addCase(getOrganizationCodeList.fulfilled, (state, action) => {
      state.ORGANIZATION_CODE_LIST = action.payload;
    })
    .addCase(getImsList.fulfilled, (state, action) => {
      state.IMS = action.payload;
    })
    .addCase(getCountries.fulfilled, (state, action) => {
      state.COUNTRY = action.payload;
    })
    .addCase(getRetailers.fulfilled, (state, action) => {
      state.RETAILER = action.payload;
    });
});
