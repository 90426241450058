import { createAsyncThunk } from 'store/utils';
import { ClassificationGroup, ClassificationKey } from 'dto/classification';
import { CodeList } from 'dto/codeList';
import { Country } from 'dto/country';
import { ImsRegisterLite } from 'dto/imsRegister';
import { api } from '@fleet/shared';
import { Classifier } from '@fleet/shared/dto/classifier';
import { Organization } from 'dto/organization';
import qs from 'qs';

export const getClassification = createAsyncThunk<
  [ClassificationKey, Array<Classifier>],
  ClassificationKey
>('getClassification', async (groupName) => [
  groupName,
  (await api.get(`/classifications/groups/${groupName}`)).data.items,
]);

export const getCountries = createAsyncThunk<Array<Country>>(
  'countries',
  async () => (await api.get('/countries')).data.items
);

export const getStopCodeList = createAsyncThunk<Array<CodeList>>(
  'codeList',
  async () => (await api.get(`/code-lists/stop?limit=99999`)).data.items
);

export const getOrganizationCodeList = createAsyncThunk<Array<CodeList>>(
  'orgList',
  async () =>
    (
      await api.get<{ items: Array<CodeList> }>(
        `/code-lists/organization?limit=99999`
      )
    ).data.items
);

export const getImsList = createAsyncThunk<Array<ImsRegisterLite>>(
  'imsList',
  async () => (await api.get(`/ims-configurations-lite?limit=99999`)).data.items
);

export const getRetailers = createAsyncThunk<Array<Organization>>(
  'retailers',
  async () =>
    (
      await api.get<{ items: Array<Organization> }>(
        `/organizations?${qs.stringify({
          AssignedRoles: 'ORGANIZATION_ROLE.RETAILER',
          IsActive: true,
          Limit: 1000,
        })}`
      )
    ).data.items
);

export const getClassifications = createAsyncThunk(
  'getClassifications',
  async (_, { dispatch }) => {
    await Promise.all([
      dispatch(getCountries()),
      dispatch(getStopCodeList()),
      dispatch(getImsList()),
      dispatch(getOrganizationCodeList()),
      dispatch(getRetailers()),
      dispatch(getClassification(ClassificationGroup.CULTURE)),
      dispatch(getClassification(ClassificationGroup.ORGANIZATION_FILE)),
      dispatch(getClassification(ClassificationGroup.CURRENCY)),
      dispatch(getClassification(ClassificationGroup.ORGANIZATION_ROLE)),
      dispatch(getClassification(ClassificationGroup.POINT_OF_SALE_TYPE)),
      dispatch(getClassification(ClassificationGroup.ADDRESS_TYPE)),
      dispatch(
        getClassification(ClassificationGroup.RETAILER_SERVICE_FEE_TYPE)
      ),
      dispatch(getClassification(ClassificationGroup.TEMPLATE_TYPE)),
      dispatch(getClassification(ClassificationGroup.CONTACTING_OPTION_TYPE)),
      dispatch(getClassification(ClassificationGroup.CONTACTING_PURPOSE_TYPE)),
      dispatch(getClassification(ClassificationGroup.POS_TYPE)),
      dispatch(getClassification(ClassificationGroup.BANK_ACCOUNT_TYPE)),
      dispatch(getClassification(ClassificationGroup.CARD_TYPE)),
      dispatch(getClassification(ClassificationGroup.PRODUCT_CATEGORY)),
    ]);
  }
);
