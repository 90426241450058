import { FC, useCallback } from 'react';
import { Button, Icon, Table, TableColumns, Tooltip } from '@fleet/shared';
import { useDispatch, useSelector } from 'store/utils';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { alliancesListSelector } from 'features/alliance/allianceSelectors';
import { Alliance } from 'dto/alliance';
import { TransTableHead } from 'i18n/trans/table';
import { usePagination, useRowSelect, useTable } from 'react-table';
import { useRowActive } from '@fleet/shared/hooks/useRowActive';
import { useRowSelectCheckbox } from '@fleet/shared/hooks';
import { makeStyles } from '@mui/styles';
import { CardContent, Stack, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransButton } from 'i18n/trans/button';
import {
  deleteAlliances,
  getAlliancesList,
} from 'features/alliance/allianceActions';

const useStyles = makeStyles(
  () => ({
    link: {
      textDecoration: 'none',
    },
  }),
  {
    name: 'AlliancesTable',
  }
);

interface AlliancesTableProps {}

export const AlliancesTable: FC<AlliancesTableProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const data = useSelector(alliancesListSelector);
  const { id } = useParams<{ action: 'create' | 'edit'; id?: string }>();
  const columns = useMemo<TableColumns<Alliance>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
        Cell: ({ row, value }) => (
          <Link
            className={classes.link}
            to={`/alliances/edit/${row.original.id}`}
          >
            {value}
          </Link>
        ),
      },
      {
        accessor: 'code',
        Header: <TransTableHead i18nKey="code" />,
      },
      {
        accessor: 'priority',
        Header: <TransTableHead i18nKey="priority" />,
      },
      {
        id: 'organizations',
        accessor: ({ organizations }) => organizations.length,
        Header: <TransTableHead i18nKey="members" />,
      },
      {
        Header: <TransTableHead i18nKey="regionalRestrictions" />,
        accessor: 'regionalRestrictions',
        Cell: ({ value }) => {
          const str = value.map(({ country: { name } }) => name).join(', ');
          return (
            <Tooltip delay={10} content={str} placement="top">
              <div
                style={{
                  maxWidth: '100%',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {str}
              </div>
            </Tooltip>
          );
        },
      },
    ],
    [classes.link]
  );

  const getRowId = useCallback((row: Alliance) => row.id, []);
  const table = useTable(
    {
      data,
      columns,
      initialState: {
        activeRowId: id,
      },
      getRowId,
    },
    usePagination,
    useRowActive,
    useRowSelect,
    useRowSelectCheckbox
  );

  const { activeFlatRow, resetRowActive } = table;

  useEffect(() => {
    if (activeFlatRow) {
      history.push(`/alliances/edit/${activeFlatRow.original.id}`);
    }
  }, [activeFlatRow, history]);

  useEffect(() => {
    if (!id) {
      resetRowActive();
    }
  }, [id, dispatch, resetRowActive]);

  const selectedRowIds = useMemo(
    () => Object.keys(table.state.selectedRowIds),
    [table.state.selectedRowIds]
  );
  const hasSelectedRows = useMemo(
    () => Boolean(selectedRowIds.length),
    [selectedRowIds]
  );

  const handleDelete = useCallback(async () => {
    await dispatch(deleteAlliances(selectedRowIds));
    dispatch(getAlliancesList());
  }, [dispatch, selectedRowIds]);

  return (
    <Table
      caption={
        <CardContent sx={{ padding: '16px 8px' }}>
          <Stack direction="row" alignItems="center">
            <Typography variant="body2" color="text.secondary" sx={{ ml: 2 }}>
              <TransSubtitle
                i18nKey="alliancesQty"
                values={{ num: data.length }}
              />
            </Typography>
            <Button
              startIcon={<Icon name="trash" size={18} />}
              sx={{ ml: 'auto', px: 1, py: 0 }}
              onClick={handleDelete}
              disabled={!hasSelectedRows}
              variant="text"
            >
              <TransButton i18nKey="deleteSelected" />
            </Button>
          </Stack>
        </CardContent>
      }
      table={table}
    />
  );
};
