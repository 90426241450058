import { PaginationParams } from '@fleet/shared/dto/pagination';

export const auditActionTypes = ['insert', 'update', 'delete'] as const;

export const auditTrailTypes = [
  'organization',
  'organization-pointOfSale',
  'organization-unit',
  'organization-retailer-service-fee',
  'organization-contacting-option',
  'organization-bank-account',
  'organization-ticket-text',
  'organization-address',
  'stop',
  'ims-configuration',
  'alliance',
  'template',
  'code-list',
  'user',
  'card-type',
] as const;

export type AuditActionType = typeof auditActionTypes[number];
export type AuditTrailType = typeof auditTrailTypes[number];

export interface AuditTrail {
  id: string;
  entityId: string;
  relatedEntityId?: string;
  auditActionType: AuditActionType;
  auditTrailType: AuditTrailType;
  createInfo: {
    createdOn: Date;
    userId: string;
    userName: string;
  };
}

export interface AuditTrailState extends AuditTrail {
  previousStateId: string;
  state: string; // JSON string
  previousState?: string; // JSON string
}

export interface AuditSearchFormValues {}

export interface AuditSearchFilter
  extends AuditSearchFormValues,
    PaginationParams {}
