import type { FC, MouseEventHandler } from 'react';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useMemo } from 'react';
import { usePagination, useTable } from 'react-table';
import { SearchResult, Table, TableColumns } from '@fleet/shared';
import { AuditSearchForm } from 'routes/audit/AuditSearchForm';
import { CardContent, Divider, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'store/utils';
import {
  auditFilterSelector,
  auditTrailSelector,
} from 'features/audit/auditSelectors';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { getAuditTrail } from 'features/audit/auditActions';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { AuditTrail, AuditTrailType, auditTrailTypes } from 'dto/audit';
import { TransTableHead } from 'i18n/trans/table';
import { TransField } from 'i18n/trans/field';
import { AuditState } from 'routes/audit/AuditState';
import { Link, useHistory, useParams } from 'react-router-dom';
import { OrganizationTab, OrganizationLocationState } from 'dto/organization';
import { useRowActive } from '@fleet/shared/hooks/useRowActive';
import { auditLoadingSelector } from 'features/loading/loadingSelectors';

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  {
    name: 'AuditTable',
  }
);

interface AuditTableProps {}

const entityLink = (
  auditTrail: AuditTrail
): { pathname: string; state?: OrganizationLocationState } | undefined => {
  const { auditTrailType, entityId, relatedEntityId } = auditTrail;
  const tab = {
    'organization-contacting-option': 'contactingOptions',
    'organization-unit': 'units',
    'organization-pointOfSale': 'pointsOfSale',
    'organization-retailer-service-fee': 'serviceFees',
  } as Record<AuditTrailType, OrganizationTab>;
  const link = (() => {
    switch (auditTrailType) {
      case 'alliance':
        return `alliances/edit`;
      case 'ims-configuration':
        return `configuration/ims-registers/edit`;
      case 'organization':
      case 'organization-contacting-option':
      case 'organization-unit':
      case 'organization-pointOfSale':
      case 'organization-retailer-service-fee':
        return `organizations`;
      case 'stop':
        return `stops/edit`;
      case 'user':
        return `users/edit`;
      default:
        return undefined;
    }
  })();

  return (
    link && {
      pathname: `${link}/${relatedEntityId || entityId}`,
      ...(relatedEntityId && {
        state: {
          tab: tab[auditTrailType],
          entityId,
        },
      }),
    }
  );
};

export const AuditTable: FC<AuditTableProps> = () => {
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const auditTrail = useSelector(auditTrailSelector);
  const filter = useSelector(auditFilterSelector);
  const dispatch = useDispatch();
  const loading = useSelector(auditLoadingSelector);

  useEffect(() => {
    if (!auditTrail?.items.length) history.replace('/audit');
  }, [auditTrail?.items.length, history]);

  const columns = useMemo<TableColumns<AuditTrail>>(
    () => [
      {
        id: 'createdOn',
        accessor: ({ createInfo }) => createInfo.createdOn,
        Header: () => <TransTableHead i18nKey="dateTime" />,
      },
      {
        id: 'user',
        accessor: ({ createInfo }) => createInfo.userName,
        Header: () => <TransTableHead i18nKey="user" />,
      },
      {
        id: 'auditTrailType',
        accessor: ({ auditTrailType }) => {
          if (auditTrailTypes.includes(auditTrailType)) {
            return <TransField i18nKey={`auditTrailType.${auditTrailType}`} />;
          }
          return '';
        },
        Header: () => <TransTableHead i18nKey="auditTrailType" />,
      },
      {
        id: 'entityId',
        accessor: 'entityId',
        Header: () => <TransTableHead i18nKey="auditActionType" />,
        Cell: ({ value, row }) => {
          const link = entityLink(row.original);
          if (link)
            return (
              <Link to={link}>
                <TransField
                  i18nKey={`auditActionType.${row.original.auditActionType}`}
                />
              </Link>
            );
          return value;
        },
      },
      {
        id: 'executionState',
        accessor: 'id',
        Header: () => <TransTableHead i18nKey="auditExecutionState" />,
        Cell: ({ value }) => <AuditState id={value} />,
      },
    ],
    []
  );

  const data = useMemo(() => auditTrail?.items ?? [], [auditTrail]);

  const getPage = useCallback(
    (pageSize: number) => {
      if (auditTrail) {
        const { limit = pageSize, offset } = auditTrail;
        return offset / limit;
      }
      return 0;
    },
    [auditTrail]
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(
        getAuditTrail({ ...filter, ...paginationParams })
      ).unwrap(),
    [dispatch, filter]
  );
  const initialState = useMemo(() => ({ pageSize: 10, ...filter }), [filter]);
  const getRowId = useCallback((row: AuditTrail) => row.id, []);
  const table = useTable(
    {
      data,
      columns,
      initialState,
      pageCount: -1,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      total: auditTrail?.totalCount,
      getRowId,
    },
    usePagination,
    useRowActive
  );
  const { resetRowActive, toggleRowActive } = table;

  useEffect(() => {
    if (!id) resetRowActive();
    else toggleRowActive(id, true);
  }, [id, resetRowActive, toggleRowActive]);

  const handleRowClick = useCallback<MouseEventHandler<HTMLTableRowElement>>(
    (e) => {
      e.preventDefault();
    },
    []
  );

  const classes = useStyles();

  return (
    <>
      <AuditSearchForm />
      <Divider />
      <SearchResult results={!!data.length} loading={loading}>
        <Table
          classes={classes}
          caption={
            <CardContent>
              <Stack direction="row" alignItems="center">
                <Typography variant="subtitle" fontWeight="700">
                  <TransSubtitle i18nKey="searchResults" />
                </Typography>
              </Stack>
            </CardContent>
          }
          table={table}
          getRowProps={() => ({
            sx: {},
            onClick: handleRowClick,
          })}
        />
      </SearchResult>
    </>
  );
};
