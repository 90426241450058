import {
  Button,
  ConfirmDeleteModal,
  formatDate,
  Icon,
  SearchResult,
  Table,
  TableColumns,
  useModal,
  useRowActive,
  useRowSelectCheckbox,
} from '@fleet/shared';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { CardContent, Divider, Stack, Typography } from '@mui/material';
import { BookingFee } from 'dto/bookingFee';
import {
  deleteBookingFees,
  getBookingFees,
} from 'features/bookingFee/bookingFeeActions';
import {
  bookingFeesFilterSelector,
  bookingFeesSelector,
} from 'features/bookingFee/bookingFeeSelectors';
import { TransAlert } from 'i18n/trans/alert';
import { TransButton } from 'i18n/trans/button';
import { TransModal } from 'i18n/trans/modal';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { Row, usePagination, useRowSelect, useTable } from 'react-table';
import { BookingFeeSearchForm } from 'routes/bookingFee/BookingFeeSearchForm';
import { useDispatch, useSelector } from 'store/utils';
import { useHistory, useParams } from 'react-router-dom';

interface BookingFeeTableProps {}

export const BookingFeeTable: FC<BookingFeeTableProps> = () => {
  const { id } = useParams<{ id?: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const { open: isOpen, onOpen, onClose } = useModal();
  const bookingFees = useSelector(bookingFeesSelector);
  const filter = useSelector(bookingFeesFilterSelector);
  const data = useMemo(() => bookingFees?.items ?? [], [bookingFees]);

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(
        getBookingFees({
          ...filter,
          ...paginationParams,
        })
      ).unwrap(),
    [dispatch, filter]
  );

  const link = useCallback(
    (row: Row<BookingFee>) => `/booking-fee/edit/${row.original.id}`,
    []
  );

  const getPage = useCallback(
    (pageSize: number) => {
      if (bookingFees) {
        const { limit = pageSize, offset } = bookingFees;
        return offset / limit;
      }

      return 0;
    },
    [bookingFees]
  );

  const columns = useMemo<TableColumns<BookingFee>>(
    () => [
      {
        id: 'retailers',
        accessor: ({ retailers }) =>
          retailers.length ? retailers.map(({ name }) => name).join(', ') : '-',
        Header: <TransTableHead i18nKey="retailers" />,
      },
      {
        id: 'pointOfSaleType.name',
        accessor: ({ pointOfSaleType }) => pointOfSaleType.name,
        Header: <TransTableHead i18nKey="pointOfSaleType" />,
      },
      {
        id: 'productCategory.name',
        accessor: ({ productCategory }) => productCategory.name,
        Header: <TransTableHead i18nKey="productCategory" />,
      },
      {
        id: 'currency.name',
        accessor: ({ currency }) => currency.name,
        Header: <TransTableHead i18nKey="currency" />,
      },
      {
        id: 'validityPeriod.from',
        accessor: ({ validityPeriod }) =>
          validityPeriod.from ? formatDate(validityPeriod.from) : '-',
        Header: <TransTableHead i18nKey="validFrom" />,
      },
      {
        id: 'validityPeriod.to',
        accessor: ({ validityPeriod }) =>
          validityPeriod.to ? formatDate(validityPeriod.to) : '-',
        Header: <TransTableHead i18nKey="validTo" />,
      },
    ],
    []
  );

  const table = useTable<BookingFee>(
    {
      data,
      columns,
      pageCount: -1,
      total: bookingFees?.totalCount,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
    },
    usePagination,
    useRowActive,
    useRowSelect,
    useRowSelectCheckbox
  );

  const { activeFlatRow, resetRowActive, selectedFlatRows } = table;

  useEffect(() => {
    if (activeFlatRow) {
      history.replace(link(activeFlatRow));
    }
  }, [activeFlatRow, history, link]);

  useEffect(() => {
    if (!id) {
      resetRowActive();
    }
  }, [id, resetRowActive]);

  const handleRowsRemove = useCallback(async () => {
    await dispatch(
      deleteBookingFees(selectedFlatRows.map(({ original }) => original.id))
    ).unwrap();
    onClose();
    alert.success(<TransAlert i18nKey="bookingFeeDeleted" />);
  }, [dispatch, selectedFlatRows, onClose, alert]);

  return (
    <>
      <BookingFeeSearchForm />
      <Divider />
      <SearchResult results={!!data.length}>
        <Table
          caption={
            <CardContent>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="row" alignItems="center">
                  <Typography variant="subtitle" fontWeight="700">
                    <TransSubtitle i18nKey="searchResults" />
                  </Typography>
                  {Boolean(bookingFees?.totalCount) && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ ml: 2 }}
                    >
                      <TransSubtitle
                        i18nKey="bookingFeesQty"
                        values={{
                          count: bookingFees ? bookingFees.totalCount : 0,
                        }}
                        tOptions={{ postProcess: 'interval' }}
                      />
                    </Typography>
                  )}
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Typography variant="body2" color="text.secondary">
                    <TransSubtitle
                      i18nKey="selectedQty"
                      values={{
                        num: table.selectedFlatRows.length,
                      }}
                    />
                  </Typography>
                  <Button
                    variant="text"
                    startIcon={<Icon name="delete" />}
                    onClick={onOpen}
                    disabled={table.selectedFlatRows.length === 0}
                    color="error"
                  >
                    <TransButton i18nKey="delete" />
                  </Button>
                </Stack>
              </Stack>
            </CardContent>
          }
          table={table}
        />
        <ConfirmDeleteModal
          handleDelete={handleRowsRemove}
          title={<TransModal i18nKey="deleteBookingFees" />}
          description={<TransModal i18nKey="bookingFeesDeletionDescription" />}
          isOpen={isOpen}
          onClose={onClose}
        />
      </SearchResult>
    </>
  );
};
