import { organizationsListLoadingSelector } from 'features/loading/loadingSelectors';
import { FC, useCallback, useMemo } from 'react';
import { OrganizationSearchForm } from 'routes/organizations/OrganizationSearchForm';
import Divider from '@mui/material/Divider';
import { Button, CardContent, Stack, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';

import type { Row, UseTableOptions } from 'react-table';
import {
  useMountedLayoutEffect,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';
import { useRowActive } from '@fleet/shared/hooks/useRowActive';
import { useIndeterminateRowSelectCheckbox } from '@fleet/shared/hooks';
import { useDispatch, useSelector } from 'store/utils';
import {
  organizationsFilterSelector,
  organizationsSelector,
} from 'features/organization/organizationSelectors';
import {
  Icon,
  Loadable,
  SearchResult,
  Table,
  TableColumns,
} from '@fleet/shared';
import { Organization } from 'dto/organization';
import { TransTableHead } from 'i18n/trans/table';
import { Link } from 'react-router-dom';
import { TransButton } from 'i18n/trans/button';
import {
  deactivateOrganizations,
  getOrganizationsList,
} from 'features/organization/organizationActions';
import { PaginationParams } from '@fleet/shared/dto/pagination';

export interface OrganizationsTableProps
  extends Partial<UseTableOptions<Organization>> {
  actions?: boolean;
  onSelectedRowsChange?: (rows: Row<Organization>[]) => void;
}

export const OrganizationsTable: FC<OrganizationsTableProps> = ({
  onSelectedRowsChange,
  actions = true,
  ...options
}) => {
  const organization = useSelector(organizationsSelector);
  const loading = useSelector(organizationsListLoadingSelector);
  const filter = useSelector(organizationsFilterSelector);
  const dispatch = useDispatch();
  const getPage = useCallback(
    (pageSize: number) => {
      if (organization) {
        const { limit = pageSize, offset } = organization;
        return offset / limit;
      }
      return 0;
    },
    [organization]
  );
  const data = useMemo(() => organization?.items ?? [], [organization]);

  const columns = useMemo<TableColumns<Organization>>(
    () => [
      {
        Header: <TransTableHead i18nKey="name" />,
        accessor: 'name',
        Cell: ({
          row: {
            original: { name, id },
          },
        }) => <Link to={`/organizations/edit/${id}`}>{name}</Link>,
      },
      {
        accessor: 'roles',
        Header: <TransTableHead i18nKey="roles" />,
        Cell: ({
          row: {
            original: { roles },
          },
        }) => roles.map((role) => role.name).join(', '),
      },
      {
        accessor: 'registrationCode',
        Header: <TransTableHead i18nKey="registrationCode" />,
      },
      {
        accessor: 'isActive',
        disableSortBy: true,
        Header: <TransTableHead i18nKey="active" />,
        Cell: ({
          row: {
            original: { isActive },
          },
        }) => isActive && <Icon name="check" color="success" />,
      },
    ],
    []
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(
        getOrganizationsList({ ...filter, ...paginationParams })
      ).unwrap(),
    [dispatch, filter]
  );

  const initialState = useMemo(() => ({ pageSize: 10, ...filter }), [filter]);
  const getRowId = useCallback((row: Organization) => row.id, []);
  const table = useTable(
    {
      data,
      columns,
      initialState,
      pageCount: -1,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      total: organization?.totalCount,
      getRowId,
      ...options,
    },
    usePagination,
    useRowActive,
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const selectedRowIds = useMemo(
    () => Object.keys(table.state.selectedRowIds),
    [table.state.selectedRowIds]
  );
  const hasSelectedRows = useMemo(
    () => Boolean(selectedRowIds.length),
    [selectedRowIds]
  );

  const handleDeactivate = useCallback(async () => {
    await dispatch(deactivateOrganizations(selectedRowIds));
    dispatch(getOrganizationsList());
  }, [dispatch, selectedRowIds]);

  useMountedLayoutEffect(() => {
    onSelectedRowsChange && onSelectedRowsChange(table.selectedFlatRows);
  }, [onSelectedRowsChange, table.selectedFlatRows]);

  return (
    <>
      <OrganizationSearchForm />
      <Divider />
      <Loadable loading={loading}>
        <SearchResult results={!!data.length}>
          <Table
            caption={
              <CardContent sx={{ padding: '16px 24px' }}>
                <Stack direction="row" alignItems="baseline">
                  <Typography variant="subtitle" fontWeight="700">
                    <TransSubtitle i18nKey="searchResults" />
                  </Typography>
                  {!!organization?.totalCount && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ ml: 2 }}
                    >
                      <TransSubtitle
                        i18nKey="organizationsQty"
                        values={{ num: organization?.totalCount }}
                      />
                    </Typography>
                  )}
                  {actions && (
                    <>
                      <Button
                        startIcon={<Icon name="error-circle" />}
                        sx={{ ml: 'auto', px: 1, py: 0 }}
                        onClick={handleDeactivate}
                        disabled={!hasSelectedRows}
                      >
                        <TransButton i18nKey="deactivateSelected" />
                      </Button>
                    </>
                  )}
                </Stack>
              </CardContent>
            }
            table={table}
          />
        </SearchResult>
      </Loadable>
    </>
  );
};
