import { FC, useMemo, useCallback } from 'react';
import {
  FormProvider,
  useForm,
  Table,
  TableColumns,
  useFormTable,
  useRowEditActions,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import { useRowSelect, useFilters } from 'react-table';
import { OrganizationBankAccount } from 'dto/organization';
import { TransTableHead } from 'i18n/trans/table';
import { Icon } from '@fleet/shared/mui';
import { Box, Button, Stack, Typography } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { useSelector, useDispatch } from 'store/utils';
import { useFormTableControls } from '@fleet/shared/hooks';
import { currentOrganizationSelector } from 'features/organization/organizationSelectors';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import {
  getOrganization,
  updateOrCreateBankAccount,
  removeBankAccounts,
} from 'features/organization/organizationActions';
import { renderToString } from 'react-dom/server';
import { TransValidate } from 'i18n/trans/validate';
import { TransSubtitle } from 'i18n/trans/subtitle';

interface BankAccountsProps {
  data: Array<OrganizationBankAccount>;
}

export const BankAccounts: FC<BankAccountsProps> = ({ data }) => {
  const dispatch = useDispatch();
  const organization = useSelector(currentOrganizationSelector);

  const bankAccountTypes = useClassificationOptions(
    ClassificationGroup.BANK_ACCOUNT_TYPE
  );
  const unitOptions = useMemo(() => {
    if (organization) {
      const options = organization.units.map(({ id, name }) => ({
        value: id,
        label: name,
      }));

      return [...options, { value: null, label: '—' }];
    }
  }, [organization]);

  const bankGiro = 'BANK_ACCOUNT_TYPE.BANKGIRO';
  const plusGiro = 'BANK_ACCOUNT_TYPE.PLUSGIRO';
  const bankAccount = 'BANK_ACCOUNT_TYPE.BANK_ACCOUNT';

  const columns: TableColumns<OrganizationBankAccount> = useMemo(
    () => [
      {
        accessor: 'organizationUnitId',
        Header: <TransTableHead i18nKey="unit" />,
        type: 'select',
        editableProps: {
          options: unitOptions,
          required: false,
        },
      },
      {
        id: 'type.id',
        accessor: ({ type }) => type?.id,
        Header: <TransTableHead i18nKey="type" />,
        type: 'select',
        editableProps: {
          options: bankAccountTypes,
        },
      },
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="bankName" />,
        conditions: {
          disabled: {
            when: 'type.id',
            is: (val) => val !== bankAccount,
          },
        },
        editableProps: {
          required: false,
          validate: (value, row) => {
            if (!value && row?.type?.id === bankAccount) {
              return renderToString(<TransValidate i18nKey="required" />);
            }
          },
        },
      },
      {
        accessor: 'giroNumber',
        Header: <TransTableHead i18nKey="giroNumber" />,
        conditions: {
          disabled: {
            when: 'type.id',
            is: (val) => val === bankAccount,
          },
        },
        editableProps: {
          required: false,
          validate: (value, row) => {
            if (
              !value &&
              (row?.type?.id === bankGiro || row?.type?.id === plusGiro)
            ) {
              return renderToString(<TransValidate i18nKey="required" />);
            }
          },
        },
      },
      {
        accessor: 'clearingNumber',
        Header: <TransTableHead i18nKey="clearingNumber" />,
        conditions: {
          disabled: {
            when: 'type.id',
            is: (val) => val !== bankAccount,
          },
        },
        editableProps: {
          required: false,
          validate: (value, row) => {
            if (!value && row?.type?.id === bankAccount) {
              return renderToString(<TransValidate i18nKey="required" />);
            }
          },
        },
      },
      {
        accessor: 'iban',
        Header: <TransTableHead i18nKey="iban" />,
        conditions: {
          disabled: {
            when: 'type.id',
            is: (val) => val === bankGiro || val === plusGiro,
          },
        },
        editableProps: {
          required: false,
        },
      },
      {
        accessor: 'accountNumber',
        Header: <TransTableHead i18nKey="accountNumber" />,
        conditions: {
          disabled: {
            when: 'type.id',
            is: (val) => val === bankGiro || val === plusGiro,
          },
        },
        editableProps: {
          required: false,
          validate: (value, row) => {
            if (!value && row?.type?.id === bankAccount) {
              return renderToString(<TransValidate i18nKey="required" />);
            }
          },
        },
      },
    ],
    [bankAccountTypes, bankGiro, plusGiro, unitOptions]
  );
  const { form } = useForm<{ rows: Array<OrganizationBankAccount> }>({
    initialValues: {
      rows: data,
    },
  });

  const table = useFormTable<OrganizationBankAccount>(
    {
      data,
      columns,
      form,
      initialState: {
        hiddenColumns: ['id'],
      },
      onRowUpdate: async ({ type, ...values }) => {
        const payload = { typeId: type.id, ...values };

        await dispatch(updateOrCreateBankAccount(payload)).unwrap();
        await dispatch(getOrganization());
      },
    },
    useFilters,
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const onRowsRemove = useCallback(
    async (payload: Array<OrganizationBankAccount>) => {
      await dispatch(removeBankAccounts(payload));
      await dispatch(getOrganization());
    },
    [dispatch]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <FormProvider {...form}>
      <Box sx={{ mb: 6 }}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Button
            variant="text"
            onClick={removeSelectedRows}
            startIcon={<Icon name="delete" />}
            disabled={!Object.keys(table.state.selectedRowIds).length}
            color="error"
          >
            <TransButton i18nKey="deleteSelected" />
          </Button>
          <Button
            variant="text"
            onClick={addRow}
            startIcon={<Icon name="plus" />}
          >
            <TransButton i18nKey="addNew" />
          </Button>
        </Stack>
        <Table table={table} />
        {Boolean(!table.rows.length) && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ m: 2, fontSize: 14 }}
          >
            <TransSubtitle i18nKey="noBankAccountsFound" />
          </Typography>
        )}
      </Box>
    </FormProvider>
  );
};
