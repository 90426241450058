import { FC, useMemo, useCallback, ChangeEvent } from 'react';
import {
  FormProvider,
  useForm,
  Table,
  TableColumns,
  useFormTable,
  useRowEditActions,
  useIndeterminateRowSelectCheckbox,
  Checkbox,
} from '@fleet/shared';
import { useRowSelect, useFilters } from 'react-table';
import { OrganizationTicketText } from 'dto/organization';
import { TransTableHead } from 'i18n/trans/table';
import { Icon } from '@fleet/shared/mui';
import { Box, Button, Stack, Typography } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { useSelector, useDispatch } from 'store/utils';
import { useFormTableControls } from '@fleet/shared/hooks';
import { currentOrganizationSelector } from 'features/organization/organizationSelectors';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import {
  getOrganization,
  removeTicketTexts,
  updateOrCreateTicketText,
} from 'features/organization/organizationActions';
import { formatDate } from '@fleet/shared/utils/date';
import { isBefore, isAfter, parseISO } from 'date-fns';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';

interface TicketTextsProps {
  data: Array<OrganizationTicketText>;
}

export const TicketTexts: FC<TicketTextsProps> = ({ data }) => {
  const dispatch = useDispatch();
  const organization = useSelector(currentOrganizationSelector);

  const languageOptions = useClassificationOptions(ClassificationGroup.CULTURE);
  const unitOptions = useMemo(() => {
    if (organization) {
      const options = organization.units.map(({ id, name }) => ({
        value: id,
        label: name,
      }));

      return [...options, { value: null, label: '—' }];
    }
  }, [organization]);

  const isValidAccessor = useCallback(
    (row: { validityPeriod: { from: string; to?: string } }) => {
      if (!row?.validityPeriod) return;
      const { from, to } = row.validityPeriod;
      const now = Date.now();
      const [validFromDate, validToDate] = [from, to]
        .filter(Boolean)
        .map((str) => parseISO(str!));
      const validityStarted = isAfter(now, validFromDate);
      const validityActive = validToDate ? isBefore(now, validToDate) : true;
      return validityStarted && validityActive;
    },
    []
  );

  const columns: TableColumns<OrganizationTicketText> = useMemo(
    () => [
      {
        id: 'isValid',
        accessor: isValidAccessor,
      },
      {
        accessor: 'organizationUnitId',
        Header: <TransTableHead i18nKey="unit" />,
        type: 'select',
        editableProps: {
          options: unitOptions,
          required: false,
        },
      },
      {
        id: 'language.id',
        accessor: ({ language }) => language?.id,
        Header: <TransTableHead i18nKey="language" />,
        type: 'select',
        editableProps: {
          options: languageOptions,
          required: true,
        },
      },
      {
        id: 'validityPeriod.from',
        accessor: ({ validityPeriod }) => formatDate(validityPeriod?.from),
        Header: <TransTableHead i18nKey="validFrom" />,
        type: 'date',
      },
      {
        id: 'validityPeriod.to',
        accessor: ({ validityPeriod }) => formatDate(validityPeriod?.to),
        Header: <TransTableHead i18nKey="validTo" />,
        type: 'date',
        conditions: {
          disabled: {
            when: 'validityPeriod.from',
            is: '',
          },
        },
        editableProps: {
          required: false,
        },
      },
      {
        accessor: 'text',
        Header: <TransTableHead i18nKey="text" />,
        editableProps: {
          required: true,
        },
      },
    ],
    [isValidAccessor, languageOptions, unitOptions]
  );
  const { form } = useForm<{ rows: Array<OrganizationTicketText> }>({
    initialValues: {
      rows: data,
    },
  });

  const table = useFormTable<OrganizationTicketText>(
    {
      data,
      columns,
      form,
      initialState: {
        hiddenColumns: ['id', 'isValid'],
      },
      onRowUpdate: async ({ language, ...values }) => {
        const payload = {
          languageId: language.id,
          ...values,
        };

        await dispatch(updateOrCreateTicketText(payload)).unwrap();
        await dispatch(getOrganization());
      },
    },
    useFilters,
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const onRowsRemove = useCallback(
    async (payload: Array<OrganizationTicketText>) => {
      await dispatch(removeTicketTexts(payload));
      await dispatch(getOrganization());
    },
    [dispatch]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  const handleValidFilterToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      table.setFilter('isValid', e.target.checked || undefined),
    [table]
  );

  return (
    <FormProvider {...form}>
      <Box sx={{ mb: 6 }}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Checkbox
            size="small"
            sx={{ flex: 1 }}
            label={<TransField i18nKey="validTicketText" />}
            onChange={handleValidFilterToggle}
            inline
          />
          <Button
            variant="text"
            onClick={removeSelectedRows}
            startIcon={<Icon name="delete" />}
            disabled={!Object.keys(table.state.selectedRowIds).length}
            color="error"
          >
            <TransButton i18nKey="deleteSelected" />
          </Button>
          <Button
            variant="text"
            onClick={addRow}
            startIcon={<Icon name="plus" />}
          >
            <TransButton i18nKey="addNew" />
          </Button>
        </Stack>
        <Table table={table} />
        {Boolean(!table.rows.length) && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ m: 2, fontSize: 14 }}
          >
            <TransSubtitle i18nKey="noTicketTextsFound" />
          </Typography>
        )}
      </Box>
    </FormProvider>
  );
};
