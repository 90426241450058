import type { FC } from 'react';
import { FormProvider, Table, TableColumns, useForm } from '@fleet/shared';
import { useCallback, useMemo } from 'react';
import { TransTableHead } from 'i18n/trans/table';
import {
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared/hooks';
import { useRowSelect } from 'react-table';
import { Localization } from 'dto/alliance';
import { useDispatch } from 'store/utils';
import {
  useClassificationMap,
  useClassificationOptions,
} from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { Button, Stack } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import {
  createAllianceLocalization,
  deleteAllianceLocalization,
  updateAllianceLocalization,
} from 'features/alliance/allianceActions';

interface AllianceFormTermsAndConditionsProps {
  data: Array<Localization>;
}

export const AllianceLocalizationsTable: FC<AllianceFormTermsAndConditionsProps> =
  ({ data }) => {
    const dispatch = useDispatch();
    const cultures = useClassificationMap(ClassificationGroup.CULTURE);
    const cultureOptions = useClassificationOptions(
      ClassificationGroup.CULTURE
    );

    const localizations = useMemo(
      () => data.map(({ culture }) => culture) ?? [],
      [data]
    );

    const usedLocalizations = useMemo(
      () => localizations.map(({ id }) => id),
      [localizations]
    );
    const availableCultureOptions = useMemo(
      () =>
        cultureOptions.filter(
          ({ value }) => !usedLocalizations.includes(value)
        ),
      [cultureOptions, usedLocalizations]
    );

    const { form } = useForm<{ rows: Array<Localization> }>({
      initialValues: {
        rows: data,
      },
    });

    const columns: TableColumns<Localization> = useMemo(
      () => [
        {
          id: 'culture.id',
          accessor: ({ culture }) => culture?.id,
          type: 'select',
          editableProps: ({ value }) => ({
            options: [
              value && { value, label: cultures.get(value) },
              ...availableCultureOptions,
            ].filter(Boolean),
          }),

          Header: <TransTableHead i18nKey="language" />,
        },
        {
          accessor: 'name',
          Header: <TransTableHead i18nKey="name" />,
        },
        {
          accessor: 'termsAndConditions',
          Header: <TransTableHead i18nKey="termsAndConditions" />,
        },
        {
          id: 'logo',
          editableProps: {
            accept: { 'image/*': [] },
            required: false,
            multiple: false,
          },
          accessor: ({ logo }) => logo?.name ?? '',
          type: 'file',
          Header: <TransTableHead i18nKey="logo" />,
        },
      ],
      [availableCultureOptions, cultures]
    );
    const formTable = useFormTable<Localization>(
      {
        data,
        columns,
        form,
        initialState: { pageSize: 20 },
        onRowUpdate: useCallback(
          async (value) => {
            value.id
              ? await dispatch(
                  updateAllianceLocalization({
                    ...value,
                    cultureId: value.culture.id,
                  })
                ).unwrap()
              : await dispatch(
                  createAllianceLocalization({
                    ...value,
                    cultureId: value.culture.id,
                  })
                ).unwrap();
          },
          [dispatch]
        ),
      },
      useRowSelect,
      useIndeterminateRowSelectCheckbox,
      useRowEditActions
    );

    const onRowsRemove = useCallback(
      async (payload: Array<Localization>) => {
        await Promise.all(
          payload.map((row: Localization) =>
            dispatch(deleteAllianceLocalization(row.id))
          )
        );
      },
      [dispatch]
    );

    const { addRow, removeSelectedRows } = useFormTableControls({
      table: formTable,
      form,
      removeQuery: onRowsRemove,
    });

    return (
      <FormProvider {...form}>
        <Stack direction="row" alignItems="center" justifyContent="end">
          <div>
            <Button
              startIcon={<Icon name="trash" />}
              onClick={removeSelectedRows}
            >
              <TransButton i18nKey="deleteSelected" />
            </Button>
            <Button startIcon={<Icon name="plus" />} onClick={addRow}>
              <TransButton i18nKey="addNew" />
            </Button>
          </div>
        </Stack>
        <Table
          table={formTable}
          getHeaderGroupProps={{ sx: { backgroundColor: 'common.white' } }}
        />
      </FormProvider>
    );
  };
