import { FC, useCallback, useMemo } from 'react';
import {
  FormProvider,
  useForm,
  Table,
  TableColumns,
  useFormTable,
  useRowEditActions,
  useIndeterminateRowSelectCheckbox,
  useRowActive,
} from '@fleet/shared';
import { useRowSelect, useFilters } from 'react-table';
import { ContactingOption } from 'dto/organization';
import { TransTableHead } from 'i18n/trans/table';
import { Icon } from '@fleet/shared/mui';
import { Box, Button, Stack, Typography } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { useDispatch, useSelector } from 'store/utils';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import {
  getOrganization,
  removeContactOptions,
  updateOrCreateContactOption,
} from 'features/organization/organizationActions';
import { useFormTableControls } from '@fleet/shared/hooks';
import { useHighLightAuditRow } from 'routes/organizations/hooks/useHighLightAuditRow';
import { currentOrganizationSelector } from 'features/organization/organizationSelectors';
import { TransSubtitle } from 'i18n/trans/subtitle';

interface ContactOptionsProps {
  data: Array<ContactingOption>;
}
export const ContactOptions: FC<ContactOptionsProps> = ({ data }) => {
  const dispatch = useDispatch();
  const organization = useSelector(currentOrganizationSelector);
  const optionTypes = useClassificationOptions(
    ClassificationGroup.CONTACTING_OPTION_TYPE
  );
  const purposeTypes = useClassificationOptions(
    ClassificationGroup.CONTACTING_PURPOSE_TYPE
  );
  const unitOptions = useMemo(() => {
    if (organization) {
      const options = organization.units.map(({ id, name }) => ({
        value: id,
        label: name,
      }));

      return [...options, { value: null, label: '—' }];
    }
  }, [organization]);

  const columns: TableColumns<ContactingOption> = useMemo(
    () => [
      {
        id: 'optionType.id',
        accessor: ({ optionType }) => optionType?.id,
        Header: <TransTableHead i18nKey="type" />,
        type: 'select',
        editableProps: { options: optionTypes },
      },
      {
        id: 'purposeType.id',
        accessor: ({ purposeType }) => purposeType?.id,
        Header: <TransTableHead i18nKey="purpose" />,
        type: 'select',
        editableProps: { options: purposeTypes },
      },
      {
        accessor: 'organizationUnitId',
        Header: <TransTableHead i18nKey="unit" />,
        type: 'select',
        editableProps: {
          options: unitOptions,
          required: false,
        },
      },
      {
        accessor: 'value',
        Header: <TransTableHead i18nKey="contactInfo" />,
      },
    ],
    [optionTypes, unitOptions, purposeTypes]
  );

  const { form } = useForm<{ rows: Array<ContactingOption> }>({
    initialValues: {
      rows: data,
    },
  });

  const table = useFormTable<ContactingOption>(
    {
      data,
      columns,
      form,
      initialState: {
        hiddenColumns: ['id'],
      },
      onRowUpdate: async ({
        optionType,
        purposeType,
        organizationUnitId,
        ...payload
      }) => {
        await dispatch(
          updateOrCreateContactOption({
            ...payload,
            organizationUnitId,
            optionTypeId: optionType.id,
            purposeTypeId: purposeType.id,
          })
        );
        await dispatch(getOrganization());
      },
    },
    useFilters,
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions,
    useRowActive
  );
  useHighLightAuditRow(table, data);

  const onRowsRemove = useCallback(
    async (payload: Array<ContactingOption>) => {
      await dispatch(removeContactOptions(payload));
      await dispatch(getOrganization());
    },
    [dispatch]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <FormProvider {...form}>
      <Box sx={{ mb: 6 }}>
        <Stack direction="row" justifyContent="flex-end" sx={{ mb: 1 }}>
          <Button
            variant="text"
            onClick={removeSelectedRows}
            startIcon={<Icon name="delete" />}
            disabled={!Object.keys(table.state.selectedRowIds).length}
            color="error"
          >
            <TransButton i18nKey="deleteSelected" />
          </Button>
          <Button
            variant="text"
            onClick={addRow}
            startIcon={<Icon name="plus" />}
          >
            <TransButton i18nKey="addNew" />
          </Button>
        </Stack>
        <Table table={table} />
        {Boolean(!table.rows.length) && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ m: 2, fontSize: 14 }}
          >
            <TransSubtitle i18nKey="noContactOptionsFound" />
          </Typography>
        )}
      </Box>
    </FormProvider>
  );
};
